import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

import { HomeComponent } from './home/home.component';
import { ConceptComponent } from './sliderpages/concept/concept.component';
import { TechnologyComponent } from './sliderpages/technology/technology.component';
import { ThreeDRComponent } from './sliderpages/three-d-r/three-d-r.component';
import { MicroscopeComponent } from './sliderpages/microscope/microscope.component';
import { TelescopeComponent } from './sliderpages/telescope/telescope.component';
import { MolyComponent } from './sliderpages/moly/moly.component';
import { AboutComponent } from './about/about.component';
import { ContactComponent } from './contact/contact.component';
import { PageNotFoundComponent } from './core/page-not-found/page-not-found.component';
import {PatentsComponent} from "./patents/patents.component";

const appRoutes: Routes = [
  // { path: '', redirectTo: 'home', pathMatch: 'full' },
  // { path: 'home', component: HomeComponent, data: { state: 'home' } },
  { path: '', component: HomeComponent, data: { state: 'home' } },
  { path: 'concept', component: ConceptComponent, data: { state: 'concept' } },
  { path: 'concept/:page', component: ConceptComponent, data: { state: 'concept' } },
  { path: 'technology', component: TechnologyComponent, data: { state: 'technology' } },
  { path: 'technology/:page', component: TechnologyComponent, data: { state: 'technology' } },
  { path: 'threedring', component: ThreeDRComponent, data: { state: 'threedring' } },
  { path: 'threedring/:page', component: ThreeDRComponent, data: { state: 'threedring' } },
  { path: 'microscope', component: MicroscopeComponent, data: { state: 'microscope' } },
  { path: 'microscope/:page', component: MicroscopeComponent, data: { state: 'microscope' } },
  { path: 'telescope', component: TelescopeComponent, data: { state: 'telescope' } },
  { path: 'telescope/:page', component: TelescopeComponent, data: { state: 'telescope' } },
  { path: 'moly', component: MolyComponent, data: { state: 'moly' } },
  { path: 'moly/:page', component: MolyComponent, data: { state: 'moly' } },
  { path: 'about', component: AboutComponent, data: { state: 'about' } },
  { path: 'contact', component: ContactComponent, data: { state: 'contact' } },
  { path: 'patents', component: PatentsComponent, data: {state: 'patents'} },
  { path: '**', component: PageNotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
