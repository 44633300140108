import {Component, OnInit, OnDestroy, AfterViewInit, Input, OnChanges, ViewChild} from '@angular/core';
import {transition, state, style, trigger, animate, query, stagger, group} from '@angular/animations';
import { ActivatedRoute, Params, Router } from '@angular/router';
import {Observable, Subscription} from 'rxjs/index';
import {map, take} from 'rxjs/operators';

import { butterService } from '../../../core/services/butterCMS.service';
import { LocalDataService } from '../../../core/services/local-data.service';
import { Meta } from '@angular/platform-browser';
import { MetaService } from 'src/app/core/services/meta.service';
// import { fadeInAnimation } from "../../../animations/fade-in.animation";

@Component({
  selector: 'app-page-card-slider',
  templateUrl: './page-card-slider.component.html',
  styleUrls: ['./page-card-slider.component.scss'],
  animations: [
    trigger('detailTransitionStart',[
      transition(':increment', [
        group([
          query('.card-body', [
            animate('500ms ease-out', style({ opacity: 0 }))
          ]),
          query('.card-right-img', [
            animate('500ms ease-out', style({ opacity: 0 }))
          ], { optional: true }),
        ])
      ]),
      transition(':decrement', [
        group([
          query('.card-body', [
            animate('500ms ease-out', style({ opacity: 0 }))
          ]),
          query('.card-right-img', [
            animate('500ms ease-out', style({ opacity: 0 }))
          ], { optional: true }),
        ])
      ])
    ]),
    trigger('detailTransitionFin',[
      transition('* => *', [
        group([
          query('.card-body', [
            animate('500ms ease-in', style({ opacity: 1 }))
          ]),
          query('.card-right-img', [
            animate('500ms ease-in', style({ opacity: 1 }))
          ], { optional: true }),
        ])
      ])
    ])
  ]
})
export class PageCardSliderComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {
  useButterCMS: boolean;
  page:any = null;
  pages:any = [];
  pagenum:number = 0;
  @Input() section: string;
  image = '';
  header: string;
  body: string;
  pageCount: number;
  changedone: boolean = false;
  paramSubscription: Subscription;
  pageslug: string;
  embedvideo: string = '';
  //using butterCMS
  protected slug$: Observable<string>;
  slugSubscriptions: Subscription;
  pageState:string = 'out';
  pageStateChangeFlag: boolean = false;
  @ViewChild('pageDetail') pageDetailChild: any;

  constructor(private localDataSrv: LocalDataService,
              private route: ActivatedRoute,
              private router: Router,
              private metaSrv: MetaService) { }
  ngOnChanges(){}
  ngOnInit() {
    // this.pageState = 'in';
    // this.pageStateChangeFlag = true;
    this.useButterCMS = this.localDataSrv.getIfUsingButterCMS();
    this.pageCount = this.getPageCount(this.section);
    if (this.useButterCMS) {
      // butterCMS approach
      this.slug$ = this.route.paramMap
        .pipe(
          map(params => (params.get('page')))
        );

     this.slugSubscriptions = this.slug$.pipe(
        take(1))
        .subscribe(slug => {
          butterService.page.retrieve(this.section, slug)
            .then((res) => {
              this.page = res.data.data;
              // console.log('butter page',  this.page);
              this.image = this.page.fields.imagename;
              this.header = this.page.fields.header;
              this.body = this.page.fields.body;
              this.embedvideo = this.page.fields.embedvideo;
              this.pageslug = this.page.slug;
              this.pagenum = parseInt(this.pageslug.slice(-1)) -1;
              //meta//
              let metadescription = this.page.fields.metadescription;
              let metatitle = this.page.fields.metatitle;
              let ogimage = this.page.fields.ogimage;
              let twittercard = this.page.fields.twittercard;
              this.metaSrv.updateMetaTags(this.router.url, metadescription, metatitle, ogimage, twittercard);
            }).catch((res) => {
            console.log('error', res);
          });
        });

      /*this. paramSubscription = this.route.params
        .subscribe(
          (params: Params) => {
            this.pageslug = params['page'];
            this.localDataSrv.getButterPage(this.section, this.pageslug)
              .subscribe(
                data => {
                  this.page = data['data'];
                },
                error => {
                  console.log('error', error);
                }
              );
          }
        );*/
    } else {
      // JSON approach
      this.setPage(this.pagenum);
      this.pagenum = this.route.snapshot.params['page'];
      this. paramSubscription = this.route.params
        .subscribe(
          (params: Params) => {
            this.pagenum = params['page'];
            this.setPage(this.pagenum);
          }
        );
      this.image = this.page.image;
      this.header = this.page.header;
      this.body = this.page.header;
      this.pageslug = this.page.slug;
      //meta//
      let metadescription = this.page.fields.metadescription;
      let metatitle = this.page.fields.metatitle;
      let ogimage = this.page.fields.ogimage;
      let twittercard = this.page.fields.twittercard;
      this.metaSrv.updateMetaTags(this.router.url, metadescription, metatitle, ogimage, twittercard);
    }
  }
  ngAfterViewInit(){
    // this._addThis.initAddThis('ra-5bd0ff487abdf586', false).subscribe();
  }

  getImagePath(){
    if (this.image === "") {
      return "";
    }else{
      if (this.image.substring(0,4) === 'http') {
        return this.image;
      }else{
        return 'assets/images/page_images/' + this.section + '/' + this.image;
      }
    }
  }
  getEmbedvideo(){
    if (this.embedvideo === "") {
      return "";
    }else{
      return this.embedvideo;
    }
  }
  onNextPage(){
    this.pagenum ++;
    if (this.pagenum >= this.pageCount) this.pagenum = 0;
    this.setPage(this.pagenum);
  }
  onPreviousPage(){
    this.pagenum --;
    if (this.pagenum < 0) this.pagenum = this.pageCount - 1;
    this.setPage(this.pagenum);
  }
  setPage(pageNum:number){
    if(pageNum < this.pageCount && pageNum > -1){
      if (this.useButterCMS) {this.localDataSrv.getButterPage(this.section, 'slide' + String(pageNum + 1))
        .subscribe(
          data => {
            this.page = data['data'];
            this.pageslug = this.page.slug;
            this.router.navigate([this.section + '/' + this.pageslug]);
          },
          error => { console.log ('setPage - error', error); }
        );
      }else{
        //local storage
        this.page = this.localDataSrv.getPage(this.section, pageNum);
      }
    }
  }
  getPageCount(pageType:string) {
    if (this.useButterCMS){
      // butterCMS approach
      this.localDataSrv.getButterPages(pageType)
        .subscribe(
          data => {
            //this.pageCount = data['data'].length;
            // console.log('data', data);
            // console.log('data[\'data\']', data['data']);
            // console.log('getPageCount()', this.pageCount);
            this.pageCount = data['meta'].count;
            // create array for page parammeters
            this.pages = [];
            for(let x = 1; x <= this.pageCount; x++){
              this.pages.push('slide' + x.toString());
            }
          },
          error => { console.log ('getPageCount error', error); }
        );
    }else {
      return this.page[pageType]['pages'].length;
    }
  }
  dotNavigate(i:number){
    // console.log(i, this.pages[i]);
    this.pagenum = i;
    this.setPage(this.pagenum);
  }
  getDotActive(i:number){
    return this.pagenum === i;
  }
  isShowButtons(){
    return (this.pageCount > 1);
  }
  makeChangesEvent(event){
    // console.log('event',event);
    // console.log('this.page',this.page);
    this.image = this.page.fields.imagename;
    this.embedvideo = this.page.fields.embedvideo;
    this.changedone = !this.changedone;
  }
  makechanges(){
    if(this.useButterCMS){
      if(this.page !== null){
        if (this.embedvideo!= ''){
          // console.log('this.embedvideo!= \'\'');
          this.pageDetailChild.handleCloseVideo();
          this.header = this.page.fields.header;
          this.body = this.page.fields.body;
          this.embedvideo = ''; //kludge stopped double video appearance
          this.changedone = !this.changedone;
        }else{
          // console.log('else case');
          this.image = this.page.fields.imagename;
          this.header = this.page.fields.header;
          this.body = this.page.fields.body;
          this.embedvideo = this.page.fields.embedvideo;
          this.changedone = !this.changedone;
        }
        // console.log('page-card-slider makechanges: this.embedvideo',this.embedvideo);
        // meta //
        let metadescription = this.page.fields.metadescription;
        let metatitle = this.page.fields.metatitle;
        let ogimage = this.page.fields.ogimage;
        let twittercard = this.page.fields.twittercard;
        this.metaSrv.updateMetaTags(this.router.url, metadescription, metatitle, ogimage, twittercard);
      }
    }else{
      this.image = this.page.image;
      this.header = this.page.header;
      this.body = this.page.body;
      this.pageslug = this.page.slug;
      this.changedone = !this.changedone;
    }
  }
  ngOnDestroy(){
    // this.pageState = 'out';
    if(!this.useButterCMS){
      this.paramSubscription.unsubscribe();
      this.slugSubscriptions.unsubscribe();
    }
  }
}
