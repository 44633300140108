export const data = {
  "concept": {
    'pages':
      [
        {
          "slug": "slide1",
          "header": "Welcome to The Third Dimension",
          "body": "You never left the third dimension, but your computer desktop did. The culprit is the computer mouse.",
          "image-name": "Flatland_mouse_3_s.jpg",
          "embed-video": "",
          "meta-title": "",
          "meta-description": "",
          "og-image": "",
          "twitter-card": ""
        },
        {
          "slug": "slide2",
          "header": "Welcome to The Third Dimension",
          "body": "The mouse is trapped on the desktop surface, so travel is via a maze between any two nodes on a plane.",
          "image-name": "2D-maze-anim.gif",
          "embed-video": "",
          "meta-title": "",
          "meta-description": "",
          "og-image": "",
          "twitter-card": ""
        },
        {
          "slug": "slide3",
          "header": "Welcome to The Third Dimension",
          "body": "Jumping over the maze wall in the third dimension shortens the path from the beginning to the end of a point and click.",
          "image-name": "3D-maze-anim.gif",
          "embed-video": "",
          "meta-title": "",
          "meta-description": "",
          "og-image": "",
          "twitter-card": ""
        },
        {
          "slug": "slide4",
          "header": "Welcome to The Third Dimension",
          "body": "The Graphical User Interface operates in 3D if the graphical interface device enters the third dimension. The flat desktop becomes a 3D office.",
          "image-name": "3D_drawer.jpg",
          "embed-video": "",
          "meta-title": "",
          "meta-description": "",
          "og-image": "",
          "twitter-card": ""
        },
        {
          "slug": "slide5",
          "header": "Welcome to The Third Dimension",
          "body": "A 3D workspace solution was exhibited by Tom DeWitt (see Company) in 1983, but its two camera stereo vision technique was constrained by blind regions in the near-field and limited resolution in the far-field.",
          "image-name": "Pantomation_1983_Paris.jpg",
          "embed-video": "",
          "meta-title": "",
          "meta-description": "",
          "og-image": "",
          "twitter-card": ""
        },
        {
          "slug": "slide6",
          "header": "Welcome to The Third Dimension",
          "body": "DeWitt then discovered that he could simulate arrays of many virtual cameras using a single camera and one cross hatch diffraction grating. A patent followed in 1987. In 1997, a 3D scanner he made under National Science Foundation funding received a Technology of the Year award in a competition of small research businesses organized by NASA.",
          "image-name": "SBIR_of_the_Year.jpg",
          "embed-video": "",
          "meta-title": "",
          "meta-description": "",
          "og-image": "",
          "twitter-card": ""
        },
        {
          "slug": "slide7",
          "header": "Welcome to The Third Dimension",
          "body": "A garden variety point-and-shoot has better resolution in 2D than any 3D scanner no matter what the cost.",
          "image-name": "Casio_vs_Cyberware.jpg",
          "embed-video": "",
          "meta-title": "",
          "meta-description": "",
          "og-image": "",
          "twitter-card": ""
        },
        {
          "slug": "slide8",
          "header": "Welcome to The Third Dimension",
          "body": "Within the colors diffracted from the hologram are thousands of alternative views of the space around it. With our technology, 3D vision could be as commonplace as 2D in digital photography. <br><br>That is the concept.",
          "image-name": "white-stripe-on-mannequin.jpg",
          "embed-video": "",
          "meta-title": "",
          "meta-description": "",
          "og-image": "",
          "twitter-card": ""
        }
      ]
    }, 'technology': { 'pages':[
      {
        "slug": "slide1",
        "header": "The Technology",
        "body": "",
        "image-name": "",
        "embed-video": "",
        "meta-title": "",
        "meta-description": "",
        "og-image": "",
        "twitter-card": ""
      },
    {
      "slug": "slide2",
      "header": "The Technology",
      "body": "",
      "image-name": "",
      "embed-video": "",
      "meta-title": "",
      "meta-description": "",
      "og-image": "",
      "twitter-card": ""
    },
    {
      "slug": "slide3",
      "header": "The Technology",
      "body": "",
      "image-name": "",
      "embed-video": "",
      "meta-title": "",
      "meta-description": "",
      "og-image": "",
      "twitter-card": ""
    },
    {
      "slug": "slide4",
      "header": "The Technology",
      "body": "",
      "image-name": "",
      "embed-video": "",
      "meta-title": "",
      "meta-description": "",
      "og-image": "",
      "twitter-card": ""
    },
    {
      "slug": "slide5",
      "header": "The Technology",
      "body": "",
      "image-name": "",
      "embed-video": "",
      "meta-title": "",
      "meta-description": "",
      "og-image": "",
      "twitter-card": ""
    },
    {
      "slug": "slide6",
      "header": "The Technology",
      "body": "",
      "image-name": "",
      "embed-video": "",
      "meta-title": "",
      "meta-description": "",
      "og-image": "",
      "twitter-card": ""
    },
    {
      "slug": "slide7",
      "header": "The Technology",
      "body": "",
      "image-name": "",
      "embed-video": "",
      "meta-title": "",
      "meta-description": "",
      "og-image": "",
      "twitter-card": ""
    },
    {
      "slug": "slide8",
      "header": "The Technology",
      "body": "",
      "image-name": "",
      "embed-video": "",
      "meta-title": "",
      "meta-description": "",
      "og-image": "",
      "twitter-card": ""
    },
    {
      "slug": "slide9",
      "header": "The Technology",
      "body": "",
      "image-name": "",
      "embed-video": "",
      "meta-title": "",
      "meta-description": "",
      "og-image": "",
      "twitter-card": ""
    },
    {
      "slug": "slide10",
      "header": "The Technology",
      "body": "",
      "image-name": "",
      "embed-video": "",
      "meta-title": "",
      "meta-description": "",
      "og-image": "",
      "twitter-card": ""
    },
    {
      "slug": "slide11",
      "header": "The Technology",
      "body": "",
      "image-name": "",
      "embed-video": "",
      "meta-title": "",
      "meta-description": "",
      "og-image": "",
      "twitter-card": ""
    },
    {
      "slug": "slide12",
      "header": "The Technology",
      "body": "",
      "image-name": "",
      "embed-video": "",
      "meta-title": "",
      "meta-description": "",
      "og-image": "",
      "twitter-card": ""
    }
  ] }
}
