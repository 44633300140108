import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs/Rx';
import {Router} from '@angular/router';

import {LocalDataService} from '../core/services/local-data.service';
import {MetaService} from '../core/services/meta.service';


@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit, OnDestroy {
  httpSubscriptions: Subscription;
  page: any;
  headline: string;
  panel1: string;
  panel2: string;

  constructor(private localDataSrv: LocalDataService,
              private metaSrv: MetaService,
              private router: Router) { }

  ngOnInit() {
    this.httpSubscriptions = this.localDataSrv.getButterPage('*', 'aboutus')
      .subscribe(
        data => {
          this.page = data['data'];
          //console.log('this.page',this.page);
          this.headline = this.page.fields.headline;
          this.panel1 = this.page.fields.panel1;
          this.panel2 = this.page.fields.panel2;
          //meta//
          let metadescription = this.page.fields.metadescription;
          let metatitle = this.page.fields.metatitle;
          let ogimage = this.page.fields.ogimage;
          let twittercard = this.page.fields.twittercard;
          this.metaSrv.updateMetaTags(this.router.url, metadescription, metatitle, ogimage, twittercard);
        },
        error => {
          console.log('error', error);
        }
      );
  }

  ngOnDestroy(){
    this.httpSubscriptions.unsubscribe();
  }

}
