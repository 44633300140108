import { Component, OnInit } from '@angular/core';
import { LocalDataService } from '../core/services/local-data.service';
import { Subscription } from 'rxjs';
import {Router} from '@angular/router';

import {MetaService} from '../core/services/meta.service';


@Component({
  selector: 'app-patents',
  templateUrl: './patents.component.html',
  styleUrls: ['./patents.component.scss']
})
export class PatentsComponent implements OnInit {
  httpSubscriptions: Subscription;
  page: any;
  panel1: string;
  panel2: string;
  panel3: string;
  panel4: string;
  panel5: string;
  pageslug: string = '';

  constructor(private localDataSrv: LocalDataService,
              private metaSrv: MetaService,
              private router: Router) { }

  ngOnInit() {
    this.httpSubscriptions = this.localDataSrv.getButterPage('*', 'patents')
      .subscribe(
        data => {
          this.page = data['data'];
          //console.log('this.page',this.page);
          this.panel1 = this.page.fields.panel1;
          this.panel2 = this.page.fields.panel2;
          this.panel3 = this.page.fields.panel3;
          this.panel4 = this.page.fields.panel4;
          this.panel5 = this.page.fields.panel5;
          this.pageslug = this.page.slug;
          //meta
          let metadescription = this.page.fields.metadescription;
          let metatitle = this.page.fields.metatitle;
          let ogimage = this.page.fields.ogimage;
          let twittercard = this.page.fields.twittercard;
          this.metaSrv.updateMetaTags(this.router.url, metadescription, metatitle, ogimage, twittercard);
        },
        error => {
          console.log('error', error);
        }
      );
  }
  ngOnDestroy(){
    this.httpSubscriptions.unsubscribe();
  }

}
