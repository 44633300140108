import {
  Component, OnInit, Input, OnChanges, ViewChild, ElementRef, Injectable, AfterContentInit,
  AfterContentChecked, AfterViewInit, AfterViewChecked, EventEmitter, Output, OnDestroy
} from '@angular/core';
import { EmbedVideoService } from 'ngx-embed-video/dist';
import {transition, state, style, trigger, animate, query, stagger, group} from '@angular/animations';

@Component({
  selector: 'app-page-detail',
  templateUrl: './page-detail.component.html',
  styleUrls: ['./page-detail.component.scss'],
  animations: [
    trigger('cardAnimation2', [
      state('*', style({ position: 'fixed', width:'100%' }) ),
      state('in', style({ transform: 'translateX(0%)' }) ),
      state('out', style({ transform: 'translateX(100%)'}) ),
      transition('* => in', [
        animate('700ms ease-out')
      ])
    ]),
    trigger('flyInOut', [
      state('in', style({ transform: 'translateX(0)' })),
      transition('void => *', [
        style({ transform: 'translateX(-100%)' }),
        animate(100)
      ]),
      transition('* => void', [
        animate(100, style({ transform: 'translateX(100%)' }))
      ])
    ]),
    trigger('cardAnimation', [
      transition('* <=> *', [
        query(':enter, :leave', style({ position: 'fixed', width:'100%' }), { optional: true }),
        group([  // block executes in parallel
          query(':enter', [
            style({ transform: 'translateX(100%)' }),
            animate('0.5s ease-in-out', style({ transform: 'translateX(0%)' }))
          ], { optional: true }),
          query(':leave', [
            style({ transform: 'translateX(0%)' }),
            animate('0.5s ease-in-out', style({ transform: 'translateX(-100%)' }))
          ], { optional: true })
        ])
      ])
    ])
  ]
})

@Injectable()
export class PageDetailComponent implements OnInit, OnChanges, AfterContentInit, AfterContentChecked, AfterViewInit, AfterViewChecked, OnDestroy {
  @Input() title: string;
  @Input() body: string;
  @Input() imagePath: string;
  @Input() embedvideostring: string = '';
  @ViewChild('matcard') matCardView: ElementRef;
  @Output() finishLeavingVideo = new EventEmitter<boolean>();
  isMatCardHeightBig:boolean = false;
  //@ViewChild('video') videoDiv: ElementRef;
  videoDivStyles: any = {};
  iframe_html: any = '';
  thestate = 'out';

  constructor(private embedService: EmbedVideoService) {
  }

  ngOnInit() {

  }
  ngOnChanges(){
    if (this.embedvideostring !== ''){
      this.imagePath = "assets/images/black_400_540.jpg";
      this.videoDivStyles = {'left':'2000px', 'position':'absolute'};
      this.iframe_html = this.embedService.embed(this.embedvideostring, {query: { portrait: 0, color: '333' }, attr: { width: 400, height: 540 }});
      setTimeout(()=>{
        this.imagePath = '';
        this.videoDivStyles = {};
      }, 100);
    }
    //console.log('in page-detail ngOnChanges - iframe_html:', this.iframe_html);
    // let w = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
    // this.isMatCardHeightBig = (w > 768) && (this.matCardView.nativeElement.offsetHeight > 560);
  }
  handleCloseVideo(){
    this.imagePath = "assets/images/black_400_540.jpg";
    this.videoDivStyles = {'left':'2000px', 'position':'absolute'};
    this.iframe_html = '';
    setTimeout(()=>{
      this.videoDivStyles = {};
      this.finishLeavingVideo.emit(true);
    }, 100);
  }
  ngAfterContentInit(){
  }
  ngAfterContentChecked(){
  }
  ngAfterViewInit(){
    // this.isMatCardHeightBig = (this.matCardView.nativeElement.offsetHeight > 560);
    this.thestate = 'in';
    console.log(this.title, this.thestate);
  }
  ngAfterViewChecked(){
    let w = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
    this.isMatCardHeightBig = (w > 767) && (this.matCardView.nativeElement.offsetHeight > 560);
  }
  ngOnDestroy(){
    this.thestate = 'out';
    console.log(this.title, this.thestate);
  }
  isCardBig(){
    return this.isMatCardHeightBig;
  }
}
