import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {HttpClientJsonpModule, HttpClientModule} from '@angular/common/http';
import {HttpModule} from '@angular/http';
import {
  MatButtonModule, MatCardModule, MatCheckboxModule, MatDatepickerModule, MatDialogModule, MatFormFieldModule,
  MatIconModule,
  MatInputModule, MatNativeDateModule, MatPaginatorModule, MatRadioModule, MatSelectModule, MatSnackBarModule
} from '@angular/material';
// import { ShareButtonsModule } from '@ngx-share/buttons';

import { AppComponent } from './app.component';
import { HeaderComponent } from './core/header/header.component';
import { FooterComponent } from './core/footer/footer.component';
import { HomeComponent } from './home/home.component';
import { ConceptComponent } from './sliderpages/concept/concept.component';
import { TechnologyComponent } from './sliderpages/technology/technology.component';
import { ThreeDRComponent } from './sliderpages/three-d-r/three-d-r.component';
import { MicroscopeComponent } from './sliderpages/microscope/microscope.component';
import { TelescopeComponent } from './sliderpages/telescope/telescope.component';
import { MolyComponent } from './sliderpages/moly/moly.component';
import { AboutComponent } from './about/about.component';
import { ContactComponent } from './contact/contact.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ParallaxModule } from 'ngx-parallax';
import { PageDetailComponent } from './shared/components/page-detail/page-detail.component';
import { LocalDataService } from './core/services/local-data.service';
import { AppRoutingModule } from './app-routing.module';
import { PageCardSliderComponent } from './shared/components/page-card-slider/page-card-slider.component';
import { EmbedVideo } from 'ngx-embed-video/dist';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { PageNotFoundComponent } from './core/page-not-found/page-not-found.component';
import { PatentsComponent } from './patents/patents.component';
import { MetaService } from './core/services/meta.service';
//import {JwSocialButtonsModule} from 'jw-angular-social-buttons';
import {ShareButtonsModule} from '@ngx-share/buttons';
//import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    ConceptComponent,
    TechnologyComponent,
    ThreeDRComponent,
    MicroscopeComponent,
    TelescopeComponent,
    MolyComponent,
    AboutComponent,
    ContactComponent,
    PageDetailComponent,
    PageCardSliderComponent,
    PageNotFoundComponent,
    PatentsComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    HttpModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    HttpClientJsonpModule,
    MatPaginatorModule,
    MatButtonModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatCardModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatRadioModule,
    MatSnackBarModule,
    MatDialogModule,
    MatIconModule,
    ParallaxModule,
    EmbedVideo.forRoot(),
    //JwSocialButtonsModule,
   // FontAwesomeModule,
    ShareButtonsModule.forRoot()
  ],
  providers: [LocalDataService, MetaService],
  bootstrap: [AppComponent]
})
export class AppModule { }
