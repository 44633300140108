import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from "rxjs/Rx";
import {Router} from '@angular/router';

import {LocalDataService} from "../core/services/local-data.service";
import { MetaService } from '../core/services/meta.service';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit, OnDestroy {
  httpSubscriptions: Subscription;
  page: any;
  headline: string;
  panel1: string;
  panel2: string;
  panel3: string;
  panel4: string;
  pageslug: string = '';

  constructor(private localDataSrv: LocalDataService,
              private metaSrv: MetaService,
              private router: Router) { }

  ngOnInit() {
    this.httpSubscriptions = this.localDataSrv.getButterPage('*', 'homepage')
      .subscribe(
        data => {
          this.page = data['data'];
          this.headline = this.page.fields.headline;
          this.panel1 = this.page.fields.panel1;
          this.panel2 = this.page.fields.panel2;
          this.panel3 = this.page.fields.panel3;
          this.panel4 = this.page.fields.panel4;
          this.pageslug = this.page.slug;
          let metadescription = this.page.fields.metadescription;
          let metatitle = this.page.fields.metatitle;
          let ogimage = this.page.fields.ogimage;
          let twittercard = this.page.fields.twittercard;
          this.metaSrv.updateMetaTags(this.router.url, metadescription, metatitle, ogimage, twittercard);
        },
        error => {
          console.log('error', error);
        }
      );
  }
  ngOnDestroy(){
    this.httpSubscriptions.unsubscribe();
  }

}
