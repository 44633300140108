import { Meta } from '@angular/platform-browser';
import { Injectable } from '@angular/core';

@Injectable()
export class MetaService {
  imagePath = 'http://dewitt.blackhammer.com/assets/images/';
  defaultTitle = '3DeWitt Practical Holography';
  defaultDescription = 'We make holigraphic optics that makes 3D possible.';
  defaultOGimage = 'https://cdn.buttercms.com/NB1K0sUwSKWuj4GPcaUO';
  defaultTwitterCard = 'https://cdn.buttercms.com/ebfZLQAqTarJQKT3Quum';
  homeurl = 'http://dewitt.blackhammer.com'
  constructor(private meta: Meta){}

  setMetaTags(theurl:string, metadescription: string, metatitle: string, ogimage: string, twittercard: string) {
    if ( !(ogimage.substring(0,4) === 'http') ){
      ogimage = this.imagePath + ogimage;
    }
    if ( !(twittercard.substring(0,4) === 'http') ){
      twittercard = this.imagePath + twittercard;
    }
     this.meta.addTags(
      [
        { name: 'meta:description', content: metadescription },
        { name: 'og:title', content: metatitle },
        { name: 'og:description', content: metadescription },
        { name: 'og:image', content: this.imagePath + ogimage },
        { name: 'og:type', content: 'website' },
        { name: 'twitter:card', content: this.imagePath + twittercard },
        { name: 'og:url', content: this.homeurl + theurl }
      ], false);
  }
  updateMetaTags(theurl:string, metadescription: string, metatitle: string, ogimage: string, twittercard: string) {
    // console.log('updateMetaTags metadescription', metadescription);
    // console.log('updateMetaTags ogimage', ogimage);
    if (metadescription == "") {  metadescription = this.defaultDescription;  }
    if (metatitle == "") { metatitle = this.defaultTitle; }
    if (ogimage == "") { ogimage = this.defaultOGimage; }
    if (twittercard == "") { twittercard = this.defaultTwitterCard; }
    this.meta.updateTag({ name: 'meta:description', content: metadescription });
    this.meta.updateTag({ name: 'og:title', content: metatitle });
    this.meta.updateTag({ name: 'og:description', content: metadescription });
    this.meta.updateTag({ name: 'og:url', content: this.homeurl + theurl });
    if ( !(ogimage.substring(0,4) === 'http') ){
      ogimage = this.imagePath + ogimage;
    }
    this.meta.updateTag({ name: 'og:image', content: ogimage });
    this.meta.updateTag({ name: 'og:type', content: 'website' });
    if ( !(twittercard.substring(0,4) === 'http') ){
      twittercard = this.imagePath + twittercard;
    }
    this.meta.updateTag({ name: 'twitter:card', content: twittercard });
  }
}
