import {Component, ElementRef, ViewChild} from '@angular/core';
import {transition, state, style, trigger, animate, query, stagger, group} from '@angular/animations';
import { routerTransition } from './animations/router-transition.animation';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [trigger('routerTransition', [
    transition('* <=> *', [
      query(':enter, :leave', style({ position: 'fixed', width:'100%'})
        , { optional: true }),
      group([  // block executes in parallel
        query(':enter', [
          style({ opacity: 0 }), //transform:'translateX(100%)',
          animate('600ms ease-in-out', style({  opacity: 1 })) //transform: 'translateX(0%)',
        ], { optional: true }),
        query(':leave', [
          style({ opacity: 1 }), //transform: 'translateX(0%)'
          animate('600ms ease-in-out', style({  opacity: 0 }))
        ], { optional: true })
      ])
    ], {params: {startHeight: 0}})
  ])
  ]
})
export class AppComponent{
  startHeight: number;
  @ViewChild('main') mainElement: ElementRef;

  constructor(private element: ElementRef){}

  getState(outlet) {
    // console.log(outlet.activatedRouteData.state);
    return outlet.activatedRouteData.state;

  }
  setStartHeight(){
    //console.log('this.mainElement', this.mainElement);
    //console.log('startheight', this.mainElement.nativeElement.clientHeight);
    this.startHeight = 1000; // this.mainElement.nativeElement.clientHeight;
  }

}
