import * as data from './data';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/index';
import {butterService} from './butterCMS.service';
import {ButterHTTPService} from './butterHTTP.service';
import {HttpClient} from "@angular/common/http";

@Injectable()
export class LocalDataService{
  data:any;
  useButterCMS: boolean = true;
  // using butterCMS
  configUrl: string = 'https://api.buttercms.com/v2/pages/';
  authtoken: string = '2509c8aead136ac65e9d0f5b40747b4e63ceb560';

  constructor(private httpClient: HttpClient) {}


  getPages(pageType: string){
      this.data = data.data;
  }
  getPage(pageType: string, pageNum:number) {
      return this.data[pageType].pages[pageNum];
  }
  getPageCount(pageType: string) {
      return this.data[pageType].pages.length;
  }

  getButterPages(pageType: string){
    return this.httpClient.get(this.configUrl + pageType + '/?auth_token=' + this.authtoken);
  }
  getButterPage(pageType: string, slug:string){
    return this.httpClient.get(this.configUrl + pageType + '/' + slug + '/?auth_token=' + this.authtoken);
  }
  public getIfUsingButterCMS() {return this.useButterCMS;}
}
